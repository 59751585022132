import React from 'react';
import dynamic from 'next/dynamic';
import Cookies from 'cookies';

import * as COOKIE_VARIABLES from '../constants/cookieVariables';

import pageDisable from '../helpers/pageDisableHelper';
import { withConfig } from '../lib/withConfig';

const CommonLogin = dynamic(() => import('../components/Login'));

const LoginPage = () => {
  switch (process.env.NEXT_PUBLIC_APP) {
    default:
      return <CommonLogin />;
  }
};

export default pageDisable(LoginPage);

async function getServerSidePropsFunction({ req, res }) {
  const cookies = new Cookies(req, res);
  const token = cookies.get(COOKIE_VARIABLES.token);

  if (token) {
    return {
      redirect: {
        destination: '/',
        permanent: false
      }
    };
  }

  return { props: {} };
}
export const getServerSideProps = withConfig(getServerSidePropsFunction)